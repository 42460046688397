// const HOST_URL = "https://server-hojhbiozes.cn-hongkong.fcapp.run";
// const WEBAPP_URL = "https://xhchen.fun"

const HOST_URL = process.env.REACT_APP_CHAT_URL
const WEBAPP_URL = "https://an619.xyz"

module.exports = {
    WEBAPP_URL,
    HOST_URL
}
